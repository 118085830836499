import Loader from "@/components/Loader";
import NavBar from "@/components/NavBar";
import noticeModel from "@/models/notice.model";
import { useRequest } from "ahooks";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { Image } from "react-vant";
import styles from "./index.module.css";

export default () => {
  const params = useParams();
  const { data, loading } = useRequest(
    () => {
      return noticeModel.detail(Number(params.id)).then((res) => res.data);
    },
    {
      ready: !!params.id,
      refreshDeps: [params.id],
    }
  );
  return (
    <div className={styles.page}>
      <NavBar title="公告详情" />
      {!data || loading ? (
        <Loader />
      ) : (
        <>
          <div className={styles.title}>{data?.title}</div>
          <div className={styles.time}>
            {dayjs(data?.publishAt).format("YYYY.MM.DD HH:mm")}
          </div>
          <div className={styles.banner}>
            <Image src={"/images/notice/banner.png"} />
          </div>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: data?.content }}
          />
        </>
      )}
    </div>
  );
};
